// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-components-components-jsx": () => import("./../../../src/pages/Components/Components.jsx" /* webpackChunkName: "component---src-pages-components-components-jsx" */),
  "component---src-pages-components-sections-section-basics-jsx": () => import("./../../../src/pages/Components/Sections/SectionBasics.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-basics-jsx" */),
  "component---src-pages-components-sections-section-carousel-jsx": () => import("./../../../src/pages/Components/Sections/SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-carousel-jsx" */),
  "component---src-pages-components-sections-section-completed-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionCompletedExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-jsx" */),
  "component---src-pages-components-sections-section-download-jsx": () => import("./../../../src/pages/Components/Sections/SectionDownload.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-download-jsx" */),
  "component---src-pages-components-sections-section-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-examples-jsx" */),
  "component---src-pages-components-sections-section-javascript-jsx": () => import("./../../../src/pages/Components/Sections/SectionJavascript.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-jsx" */),
  "component---src-pages-components-sections-section-login-jsx": () => import("./../../../src/pages/Components/Sections/SectionLogin.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-login-jsx" */),
  "component---src-pages-components-sections-section-navbars-jsx": () => import("./../../../src/pages/Components/Sections/SectionNavbars.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-navbars-jsx" */),
  "component---src-pages-components-sections-section-notifications-jsx": () => import("./../../../src/pages/Components/Sections/SectionNotifications.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-jsx" */),
  "component---src-pages-components-sections-section-pills-jsx": () => import("./../../../src/pages/Components/Sections/SectionPills.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-pills-jsx" */),
  "component---src-pages-components-sections-section-tabs-jsx": () => import("./../../../src/pages/Components/Sections/SectionTabs.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-jsx" */),
  "component---src-pages-components-sections-section-typography-jsx": () => import("./../../../src/pages/Components/Sections/SectionTypography.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-typography-jsx" */),
  "component---src-pages-contact-page-contact-page-jsx": () => import("./../../../src/pages/ContactPage/ContactPage.jsx" /* webpackChunkName: "component---src-pages-contact-page-contact-page-jsx" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("./../../../src/pages/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-about-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/AboutSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-about-section-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-landing-page-sections-work-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-work-section-jsx" */),
  "component---src-pages-login-page-js": () => import("./../../../src/pages/login-page.js" /* webpackChunkName: "component---src-pages-login-page-js" */),
  "component---src-pages-login-page-login-page-jsx": () => import("./../../../src/pages/LoginPage/LoginPage.jsx" /* webpackChunkName: "component---src-pages-login-page-login-page-jsx" */),
  "component---src-pages-profile-page-js": () => import("./../../../src/pages/profile-page.js" /* webpackChunkName: "component---src-pages-profile-page-js" */),
  "component---src-pages-profile-page-profile-page-jsx": () => import("./../../../src/pages/ProfilePage/ProfilePage.jsx" /* webpackChunkName: "component---src-pages-profile-page-profile-page-jsx" */),
  "component---src-pages-services-page-js": () => import("./../../../src/pages/services-page.js" /* webpackChunkName: "component---src-pages-services-page-js" */),
  "component---src-pages-services-page-services-page-jsx": () => import("./../../../src/pages/ServicesPage/ServicesPage.jsx" /* webpackChunkName: "component---src-pages-services-page-services-page-jsx" */)
}

